.build-page {
  width: auto;
  margin-right: auto;
  margin-left: auto;
}

.build-content {
  width: auto;
  --background: white;
}

.build-page-div {
  display: block;
  width: 100%;
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
}

.page-header {
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.4px;
  font-size: 20px;
  line-height: 10px;
  color: black;
  margin: 12px 0px 21px 0px;
}

.price-ticker.mystery-machine {
  background: linear-gradient(
    173.16deg,
    rgba(255, 151, 220, 0.8) 23.11%,
    rgba(255, 194, 156, 0.8) 98.13%
  );
}

.building-mystery {
  display: block;
  left: 100%;
  right: 100%;
  max-width: 450px;
  max-height: 80vh;
}

.select-button {
  margin-top: 16px;
  margin-bottom: auto;
  margin-right: 40px;
  width: 80px;
}

.collection-select {
  padding: 10px 14px 4px 12px;
  min-height: 40px;
  border-radius: 12px;
  background: white;
  border: 1px solid #f0f2f3;
  box-shadow: 0px 4.52899px 9.05797px rgba(0, 0, 0, 0.02);
}

.building-mystery-group {
  display: grid;
  grid-template-columns: repeat(1, auto);
  justify-items: center;
  position: static;
  overflow: none;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  background: #ffedc1;
  height: auto;
  border-radius: 24px;
  padding: 20px 0px 20px 0px;
}

.building-price {
  text-align: center;
  letter-spacing: 0.1px;
  font-size: 21px;
  line-height: 10px;
  font-weight: 700;
  color: black;
  margin: 18px 0px 18px 0px;
}

.building-price-small {
  font-size: 12px;
}

.build-title {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  align: baseline;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.4px;
  margin-top: 6px;
  color: #9b9b9b;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-items: center;
  position: static;
}

.build-wrap {
  padding: 6px 8px 4px 8px;
  border-radius: 14px;
  background: #f7f7f7;
  margin-bottom: 12px;
  margin-top: 18px;
}

.build-wrap.right {
  margin-right: 0px;
  margin-left: 8px;
}

.build-wrap.left {
  margin-right: 8px;
  margin-left: 0px;
}

.build-wrap.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-items: center;
  position: static;
}

.build-button {
  width: auto;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 21px;
  padding: 24px 24px 24px 24px;
  background: linear-gradient(180deg, #da8aff 25.52%, #8b51e3 100%);
  box-shadow: 0px 8px #7433ca;
}

.build-button-outline {
  display: block;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  bottom: 24px;
  left: 0%;
  right: 0%;
  top: auto;
  width: 100%;
  max-width: 400px;
  height: 86px;
  padding: 3px 3px 3px 3px;
  margin-bottom: 0px;
  border-radius: 24px;
  background-color: #fff;
}

.build-button-outline:active {
  height: 81px;
  transform: translateY(3px);
}

.number-button {
  margin: 100;
  min-width: 32px;
  min-height: 32px;
  --background: #dbdcde;
  --background-activated: #4d92ff;
  --background-hover: #4d92ff;
  --color: black;
  --border-radius: 8px;
  font-weight: 700;
}

.number-button.blue {
  --background: linear-gradient(180deg, #60bafe 0%, #4592ed 100%);
  --color: white;
  font-weight: 700;
}
