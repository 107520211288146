.header {
  background-color: transparent;
}

.about-page {
  padding: 80px 0px 0px 0px;
}

.about-sub-heading {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.about-line {
  width: 100%;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #eef2f4;
}

.emoji {
  max-width: 15px;
}

.emoji-circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  margin-right: 16px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #5e5e5e;
}

.emoji-circle.green {
  background-color: #b2f0ca;
}

.emoji-circle.yellow {
  background-color: #f5eac3;
}

.emoji-circle.blue {
  width: 35px;
  height: 35px;
  background-color: #d5dce1;
}

.dark-x {
  position: relative;
  left: auto;
  top: 0px;
  right: 0px;
  bottom: 0%;
  width: 35px;
  height: 35px;
  padding: 6px;
  border-radius: 100px;
  background-color: #fff;
}

.dark-x:hover {
  background-color: rgba(238, 242, 244, 0.75);
}

.about-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 6px;
  margin-left: 6px;
}

.about-text {
  margin-bottom: 24px;
  padding: 18px;
  border-radius: 12px;
  background-color: rgba(238, 242, 244, 0.75);
  font-size: 16px;
  line-height: 24px;
}

.about-share {
  margin-top: 24px;
  margin-bottom: 12px;
  padding: 24px 12px 32px;
  border-radius: 12px;
  background-color: rgba(238, 242, 244, 0.75);
}

.about-heading {
  margin-top: 6px;
  margin-bottom: 12px;
  margin-left: 6px;
  color: #000;
  font-size: 42px;
  line-height: 48px;
  font-weight: 500;
}

.about-heading.center {
  text-align: center;
}

.about-group {
  margin-bottom: 80px;
}

.nav-text {
  color: #000;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-decoration: none;
}

.nav-text.large {
  color: #000;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.8px;
}

.nav-text.large.white {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.8px;
}

.feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 18px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e1e9ed;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(119, 133, 140, 0.15);
}

.twitter-icon {
  margin-right: 10px;
  margin-left: 0px;
}

.feature-text-2 {
  color: #000;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.back-button {
  float: right;
}

.header-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 54px;
  padding: 16px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 100px;
  background-color: #4d92ff;
  letter-spacing: 2px;
  text-decoration: none;
}

.header-button.small {
  width: 200px;
  margin-top: 18px;
  margin-right: auto;
  margin-left: auto;
}

.header-button.small.w--current {
  width: 200px;
  margin: 12px auto;
}

.about-head-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .about-pop-up {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .about-pop-up {
    max-width: 300px;
    margin-top: 172px;
    padding: 18px;
  }
}

@media screen and (max-width: 479px) {
  .about-sub-heading {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }
  .about-line {
    width: 100%;
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #eef2f4;
  }
  .emoji-circle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 30px;
    margin-right: 16px;
    padding: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 15px;
    background-color: #c4c4c4;
  }
  .emoji-circle.green {
    width: 35px;
    height: 35px;
  }
  .emoji-circle.yellow {
    width: 35px;
    height: 35px;
    border-radius: 100px;
  }
  .emoji-circle.blue {
    border-radius: 100px;
  }
  .dark-x {
    position: fixed;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 35px;
    height: 35px;
    margin-top: 84px;
    margin-right: 28px;
    padding: 6px;
    border-radius: 100px;
    background-color: #fff;
  }
  .dark-x:hover {
    background-color: rgba(238, 242, 244, 0.75);
  }
  .about-logo {
    width: 48px;
    height: 48px;
    margin-bottom: 6px;
    margin-left: 6px;
  }
  .header-image {
    display: none;
    height: 120px;
    background-position: 50% -100px;
  }
  .about-pop-up {
    position: absolute;
    left: 0%;
    top: 10%;
    right: 0%;
    bottom: 0%;
    display: none;
    overflow: scroll;
    width: 90%;
    height: 40vh;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding: 18px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 1px 10px 1px rgba(41, 89, 165, 0.1);
  }
  .about-text {
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 12px;
    background-color: rgba(238, 242, 244, 0.5);
    font-size: 14px;
  }
  .about-share {
    margin-bottom: 12px;
  }
  .about-heading {
    margin-top: 6px;
    margin-bottom: 10px;
    margin-left: 6px;
    color: #000;
    font-size: 38px;
    line-height: 42px;
    font-weight: 500;
  }
  .about-group {
    margin-bottom: 12px;
  }
  .nav-text.large.white {
    font-size: 16px;
  }
  .feature {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 8px;
    padding: 14px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e9ed;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 3px 10px 0 rgba(119, 133, 140, 0.15);
    color: #fff;
  }
  .feature-text-2 {
    color: #000;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0.4px;
  }
  .header-button {
    margin-right: 8px;
    margin-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .header-button.small {
    width: 140px;
    height: 42px;
    margin-top: 24px;
  }
  .about-head-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
