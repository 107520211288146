.ion-page > div {
  height: 100%;
}

ion-content {
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --padding-top: 0px !important;
}

ion-content.custom-background {
  --background: white !important;
}

.build-page {
  height: 100%;
  width: 100%;
  transition: background-color 300ms linear;
}

.build-content-brooklyn {
  max-width: 400px;
  --background: #f7dccb;
}

.public-nav-buttons {
  margin: 10px 0px;
}

@media screen and (max-width: 479px) {
  .public-nav-buttons {
    margin-bottom: 25px;
  }
}

.div---wood-beam {
  width: 100%;
  height: 14px;
  border-radius: 6px;
  background-color: #473f37;
}

.div---wood-beam.bottom {
  height: 14px;
  margin-top: -20px;
}

.kiosk-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0px;
  padding-bottom: 16px;
}

.div-block {
  position: relative;
  z-index: 101;
  width: 100%;
  height: 84px;
  margin-top: 5px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 14px;
  padding-right: 12px;
  padding-left: 12px;
  border: 0px solid #473f37;
  border-radius: 16px;
  background-color: #473f37;
}

.sign {
  position: relative;
  z-index: 101;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 56px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #f9f5f0;
}

.text-block {
  color: #000;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
}

.text-block._150 {
  width: auto;
  max-width: 250px;
}

.kiosk-sign {
  position: relative;
  z-index: 101;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  height: auto;
  margin-top: -10px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #473f37;
}

.kiosk-sign.bottom {
  margin-top: -20px;
}

.kiosk-text {
  padding-left: 6px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 8px;
  text-transform: uppercase;
}

.kiosk-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.kiosk-left {
  width: 125px;
  height: auto;
  margin-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.kiosk-right {
  width: 125px;
  margin-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.current-price {
  padding: 8px;
  border-radius: 12px;
  background-color: #473f37;
}

.current-price.time {
  margin-top: 8px;
}

.kiosk-subheading {
  width: 100%;
  margin-bottom: 6px;
  color: #fff;
  font-size: 8px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.subheading.left {
  margin-bottom: 0px;
  font-size: 9px;
  line-height: 14px;
  text-align: left;
}

.subheading.right {
  margin-bottom: 0px;
  margin-left: 8px;
  font-size: 8px;
  line-height: 9px;
}

.subheading.small {
  display: none;
}

.div-block-2 {
  border-radius: 8px;
  background-color: #f9f5f0;
}

.current-price-text {
  padding-top: 14px;
  padding-right: 4px;
  padding-bottom: 12px;
  border-radius: 8px;
  background-color: #f9f5f0;
  font-family: "Roboto Mono", sans-serif;
  color: #000;
  font-size: 21px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
}

.current-price-text.small {
  padding: 4px 10px;
  font-size: 16px;
}

.current-price-text.smallest {
  padding: 10px;
  font-size: 13px;
  line-height: 13px;
  margin-right: 7px;
}

.last-price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  padding: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #473f37;
}

.quant-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 42px;
  height: 42px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #f9f5f0;
  font-family: "Roboto Mono", sans-serif;
  color: #473f37;
  font-size: 18px;
  font-weight: 700;
}

.quant-button.chosen {
  background-color: #ef956f;
}

.quantity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 12px 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 12px;
  background-color: #473f37;
}

.build-button.brooklyn {
  background: linear-gradient(180deg, #fda86a 20%, #f85);
  box-shadow: 0px 8px #c85a2b;
}

.build-button.brooklyn:hover {
  opacity: 0.94;
}

.build-button.brooklyn:active {
  box-shadow: 0px 3px #c85a2b;
  transform: translateY(0px);
}


.build-button.grey {
  background: linear-gradient(180deg, #e8e8e8 0%, #bebebf 100%);
  box-shadow: 0px 8px #939393;
}

.build-button.grey:hover {
  opacity: 0.94;
}

.build-button.grey:active {
  box-shadow: 0px 3px #939393;
  transform: translateY(0px);
}

.build-button.pass {
  background: linear-gradient(180deg, #febe8f 20.31%, #ffac89 100%);
  background: #e38f59;
}

.build-button.pass:hover {
  opacity: 0.94;
}

.build-button.pass:active {
  box-shadow: 0px 3px #e38f59;
  transform: translateY(0px);
}


.constructing-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 4px;
  padding: 24px 24px 24px 24px;
  background: #fee182;
}

.constructing-text {
  display: block;
  margin-right: 32px;
  margin-left: 32px;
  font-style: italic;
  font-weight: 900;
  font-size: 19.1433px;
  line-height: 108%;
  color: #000000;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.constructing-emoji {
  display: block;
  width: 25px;
  height: 25px;

  animation: spin 3s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.build-button.pass {
  background: linear-gradient(180deg, #febe8f 20%, #ffac89);
  box-shadow: 0 8px 0 0 #e38f59;
}

.build-buttons {
  position: fixed;
  display: flex;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0px;
  display: block;
  z-index: 100;
  width: 100%;

  padding: 0px 20px;
  max-width: 550px;
  margin: 0px auto;
}

.build-button-inline {
  position: relative;
  display: inline-block;
  width: calc(50% - 10px) !important;
  flex-wrap: wrap;
}
.build-button-inline:nth-child(1) {
  margin-right: 10px;
}
.build-button-inline:nth-child(2) {
  margin-left: 10px;
}

.current-stock {
  position: static;
  top: 100px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 125px;
  margin: 8px 0px 0% auto;
  padding: 8px 8px 9px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #473f37;
}

.current-stock.info {
  width: 85px;
  margin-top: 8px;
  margin: 8px 0px 0px 0px;
}

.div-block-3 {
  position: absolute;
  pointer-events: none;
  top: auto;
  right: auto;
  left: -570px;
  bottom: -1200px;
  width: 1750px;
  height: 1750px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.04);
  transform: scale3d(1, 0.6, 1) rotate(45deg);
  transform-style: preserve-3d;
}

.building-gif {
  position: absolute;
  z-index: 10;
  left: 0px;
  top: auto;
  right: 0px;
  bottom: 120px;
  overflow: visible;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;

  width: 57%;
  height: 52%;

  max-width: none;
  max-height: none;
}

.building-gif-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;

  opacity: 0;
}

.building-gif-image.animate-drop {
  animation: building-drop 0.25s ease;
}
.building-gif-image.animate-reveal {
  animation: building-reveal 0.25s ease;
  opacity: 1;
}

@keyframes building-drop {
  0% {
    top: 0px;
    opacity: 1;
  }
  100% {
    top: 550px;
    opacity: 0;
  }
}
@keyframes building-reveal {
  0% {
    top: -550px;
  }
  100% {
    top: 0px;
  }
}

@media screen and (max-width: 479px) {
  .div-block-3 {
    left: -183%;
  }
}

.nav-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #a9a9a9;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 1000px;
}

.header {
  width: 100%;
  max-width: 950px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #ebebeb;
  font-family: Impact, Haettenschweiler, "Franklin Gothic Bold", Charcoal,
    sans-serif;
  font-size: 120px;
  line-height: 100px;
  text-align: center;
}

.header-text {
  width: 650px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ebebeb;
  font-family: Lato, sans-serif;
  font-size: 120px;
  line-height: 100px;
  text-align: center;
}

.buy-button-text {
  margin-right: auto;
  margin-left: auto;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: none;
}

.kiosk-info-modal {
  position: absolute;
  z-index: 101 !important;
  left: 0%;
  top: 300px;
  right: 0%;
  bottom: auto;
  z-index: 10;
  width: 90%;
  max-width: 420px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 15px 13px 15px;
  border-radius: 12px;
  background-color: #473f37;
}

.kiosk-info-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #f9f5f0;
}

.kiosk-body-text {
  width: auto;
  margin: 24px 24px 4px;
  font-family: "Roboto Mono", sans-serif;
  color: #fbefe8;
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 0.04px;
  text-transform: none;
}

.kiosk-body-text.left {
  margin-bottom: 0px;
  font-size: 9px;
  line-height: 14px;
  text-align: left;
}

.kiosk-body-text.right {
  margin-bottom: 0px;
  margin-left: 8px;
  font-size: 8px;
  line-height: 9px;
}

.kiosk-body-text.small {
  display: none;
}

.kiosk-body-text.dark {
  color: #83746c;
}

.price-ticker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 28px;
  margin: 0px auto 12px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0px solid #473f37;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.24);
}

.ticker-text {
  display: inline-block !important;
  width: 150px;
  margin-bottom: 0px;
  font-family: "Roboto Mono", sans-serif;
  color: #fff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.ticker-text.left {
  margin-bottom: 0px;
  font-size: 9px;
  line-height: 14px;
  text-align: left;
}

.ticker-text.right {
  margin-bottom: 0px;
  margin-left: 8px;
  font-size: 8px;
  line-height: 9px;
}

.ticker-text.small {
  display: none;
}

.ticker-text._50 {
  opacity: 0.5;
}

.ticker-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.build-button-50 {
  position: static;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 12px;
  display: block;
  overflow: hidden;
  width: 175px;
  height: 83px;
  max-width: 400px;
  margin-right: 8px;
  margin-left: 8px;
  padding: 3px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  font-family: "Roboto Mono", sans-serif;
  color: #473f37;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 4px;
  text-decoration: none;
  text-transform: uppercase;
}

.div-block-4 {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 12px;
}

.pass-build-flex {
  position: fixed;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 450px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .header-text {
    width: 90%;
  }
}

@media screen and (max-width: 767px) {
  .header-text {
    width: 90%;
  }
}

@media screen and (max-width: 479px) {
  .body {
    background-color: #fff;
  }
  .div---wood-beam {
    height: 14px;
  }
  .div---wood-beam.bottom {
    height: 14px;
    margin-top: -14px;
  }
  .kiosk-container {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    padding-top: 16px;
    padding-right: 12px;
    padding-left: 12px;
  }
  .div-block {
    height: 62px;
    margin-top: 0px;
    padding-top: 8px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .sign {
    height: 46px;
  }
  .text-block {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2.5px;
  }
  .kiosk-sign {
    width: 80px;
    margin-top: -8px;
    border-radius: 8px;
  }
  .kiosk-text {
    font-size: 8px;
    line-height: 8px;
    letter-spacing: 6px;
  }
  .kiosk-left {
    position: relative;
    z-index: 2;
    width: 125px;
    margin-top: 12px;
    margin-left: 0px;
  }
  .kiosk-right {
    position: relative;
    z-index: 2;
    width: 125px;
    margin-top: 12px;
    margin-right: 0px;
  }
  .current-price {
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .subheading {
    display: block;
    font-size: 8px;
    letter-spacing: 1px;
  }
  .subheading.left {
    display: block;
    margin-right: 6px;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 1px;
  }
  .subheading.small {
    display: block;
    font-size: 8px;
  }
  .subheading.quantity {
    display: none;
  }
  .subheading.no-mobile {
    display: none;
  }
  .current-price-text {
    padding-top: 12px;
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 22px;
  }
  .current-price-text.small {
    font-size: 11px;
    line-height: 24px;
  }
  .current-price-text.smallest {
    font-size: 13px;
  }
  .last-price {
    margin-top: 10px;
    padding: 10px;
  }
  .quant-button {
    width: 32px;
    height: 32px;
    margin-right: auto;
    margin-bottom: 6px;
    margin-left: auto;
    font-size: 14px;
  }
  .quant-button.chosen {
    width: 32px;
    height: 32px;
    margin-right: auto;
    margin-left: auto;
    font-size: 14px;
  }
  .quantity {
    width: 125px;
    margin-left: auto;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .build-button {
    position: static;
    left: auto;
    top: auto;
    right: 12px;
    bottom: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 68px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 12px 10px;
  }
  .build-button.pass {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 68px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .build-button-outline {
    z-index: 100;
    height: 84px;
    width: 90%;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #000;
    font-size: 16px;
  }
  .current-stock {
    position: static;
    left: 12px;
    top: auto;
    right: auto;
    bottom: 12px;
    width: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .header-text {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 80px;
    line-height: 70px;
  }
  .buy-button-text {
    width: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 20px;
  }
  .kiosk-info-modal {
    padding-right: 10px;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-top: 6px;
  }
  .kiosk-info-heading {
    height: auto;
    padding: 6px 24px 6px 24px;
  }
  .kiosk-body-text {
    margin: 6px 12px 4px;
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .kiosk-body-text.left {
    display: block;
    margin-right: 6px;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 1px;
  }
  .kiosk-body-text.small {
    display: block;
    font-size: 8px;
  }
  .kiosk-body-text.quantity {
    display: none;
  }
  .kiosk-body-text.no-mobile {
    display: none;
  }
  .price-ticker {
    height: 28px;
    margin-top: -14px;
    padding-top: 0px;
    padding-right: 14px;
    padding-left: 14px;
  }
  .ticker-text.left {
    display: block;
    margin-right: 6px;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 1px;
  }
  .ticker-text.small {
    display: block;
    font-size: 8px;
  }
  .ticker-text.quantity {
    display: none;
  }
  .ticker-text.no-mobile {
    display: none;
  }
  .ticker-text._50 {
    font-size: 11px;
  }
  .build-button-50 {
    height: 83px;
    margin-right: 4px;
    margin-left: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
    color: #000;
    font-size: 16px;
  }
  .pass-build-flex {
    bottom: 12px;
    width: auto;
  }
}

.text-slideshow {
  height: 28px;
  max-width: 550px;
  margin: 0 auto;
  margin-top: -35px;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.text-slideshow > div {
  box-sizing: border-box;
  width: 600px;
  position: absolute;
  top: 0px;
  left: 0;
  transform: translate3d(0, 0, 0);
}
.text-slideshow .text-slide-1 {
  animation: moveSlideshow 10s linear infinite;
}
.text-slideshow .text-slide-2 {
  animation: moveSlideshow 10s linear infinite;
  left: -600px;
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(300px);
  }
}
