ion-content {
  --padding-start: 14px;
  --padding-end: 14px;
  --padding-top: 14px;
}

ion-content.custom-background {
  --background: white !important;
}

.building-modal {
  padding: 0px 30px;
  overflow-y: scroll;
}

.ion-page .building-modal {
  padding: 30px;
}

/* .full-page .building-modal {
  padding: 30px;
  background-color: white;
} */

ion-header {
  --background: white;
}

.building-image-profile {
  padding: 32px;
  max-height: 46vh;
  max-width: 42vh;
}

.building-group-profile {
  display: grid;
  grid-template-columns: repeat(1, auto);
  justify-items: center;
  position: static;
  margin-left: auto;
  margin-right: auto;
  background: #c7eafd;
  height: 50vh;
  border-radius: 24px;
  padding: 16px;
}

.building-name-profile {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  position: static;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  color: black;
}

.body-text {
  text-align: center;
  letter-spacing: 0.4px;
  font-size: 18px;
  line-height: 10px;
  font-weight: 600;
  color: black;
  margin: 16px 0px 16px 0px;
}

.body-text.light {
  color: #8a8a8a;
  font-weight: 400;
}

.body-text.left {
  text-align: left;
  margin-left: 0px;
}

.body-text.right {
  text-align: right;
  margin-right: 0px;
}

.body-text.small {
  font-size: 14px;
  color: #8a8a8a;
  text-align: right;
}

.text-wrap {
  padding: 4px 6px 4px 6px;
  border-radius: 14px;
  background: white;
  margin-bottom: 18px;
  margin-top: 18px;
}

.text-wrap.right {
  margin-right: 0px;
  margin-left: 8px;
}

.text-wrap.left {
  margin-right: 8px;
  margin-left: 0px;
}

.text-wrap.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-items: center;
  position: static;
}

.text-wrap.middle {
  margin-right: 10px;
  margin-left: 10px;
}

.owner-group-profile {
  padding: 10px 4px 4px 6px;
  min-height: 40px;
  border-radius: 12px;
  background: white;
}

.owner-name {
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 16px;
  max-width: 200px;
  color: black;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  padding-left: 20px;
  padding-left: 12px;
  padding-top: 0px;
}

.owner-address {
  margin-top: 2px;
  color: #bfc0c0;
  font-size: 14px;
  font-weight: 600;
  line-height: 4px;
  padding-left: 12px;
}

.follow-button {
  --padding-end: 12px;
  --padding-start: 12px;
}

.remove-padding {
  --ion-grid-padding: 0px;
  --ion-grid-column-padding: 0px;
}

.details-wrap {
  padding: 10px 16px 6px 16px;
  border-radius: 14px;
  background: white;
  margin-bottom: 16px;
  margin-top: 0px;
}

.subheading {
  color: black;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: 6px;
}

.subheading-small {
  color: #b9baba;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: 0px;
}

.building-attribute-img {
  margin-top: 6px;
  margin-right: 22px;
  max-width: 30px;
}

.sm-padding {
  margin: 10px 0px 10px 0px;
  padding: 0px;
}

.no-padding {
  margin: 0px;
  padding: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

.icon {
  opacity: 0.2;
  z-index: 2;
  width: 24px;
  justify-self: end;
  position: relative;
  margin-right: 0;
  margin-left: auto;
}

.badge {
  position: static;
  justify-self: start;
  border: 1px solid #a6a6a6;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.15);
}

.building-bio-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  position: static;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  color: rgb(54, 54, 54);
}

.source-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  position: static;
  margin-top: 6px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  color: rgb(190, 190, 190);
}
