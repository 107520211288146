.start-screen {
  --background: transparent;
}

.start-logo-div {
  display: block;
  width: 90%;
  max-width: 250px;
  margin: 42px auto auto auto;
}
.start-logo-div img {
  display: block;
  margin: 0px auto;
}

.start-logo {
  position: static;
  width: 200px;
  max-width: 250px;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  border-radius: 100px;

  box-shadow: 0px 6.56px 13.12px rgba(10, 59, 99, 0.15);
}

.type-logo-div {
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 250px;
  margin: 42px auto auto auto;
}

.type-logo {
  position: static;
  width: auto;
  margin-right: auto;
  margin-left: auto;
}

.headline-text {
  margin: 0px auto auto auto;
  font-weight: bold;
  font-size: 89.8765px;
  max-width: 640px;
  line-height: 85%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-family: VanguardCF, serif;
  font-weight: bold;
}

@media only screen and (min-width: 960px) {
  .headline-text {
    font-size: 150px;
  }
}

.signup-button {
  position: static;
  width: auto;
  text-align: center;
  color: white;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 21px;
  padding: 20px 24px 20px 24px;
  background: linear-gradient(180deg, #60bafe 0%, #4592ed 100%);
  box-shadow: 0px 8px #2873ca;
}

.signup-button-outline {
  position: absolute;
  display: block;
  top: auto;
  bottom: 24px;
  left: 5vw;
  right: 5vw;
  margin: 0px auto;
  width: 90vw;
  max-width: 640px;
  height: auto;
  padding: 3px 3px 11px 3px;
  border-radius: 24px;
  background-color: #fff;
}

.signin-button-outline {
  position: absolute;
  display: block;
  top: auto;
  bottom: 132px;
  left: 7.5vw;
  right: 7.5vw;
  width: 85vw;
  max-width: 620px;
  height: auto;
  margin: 0 auto;
}

.signin-button {
  position: static;
  width: auto;
  border-radius: 18px;
  background-color: #7ec2fa;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.5px;

  padding: 16px 20px 16px 20px;

  padding: 16px 20px 16px 20px;
}

.start-header-text {
  position: static;
  margin: 0px auto 12px auto;
  font-weight: 700;
  width: 250px;
  font-size: 32px;
  line-height: 108%;
  text-align: center;
  letter-spacing: 0.4px;
  color: #000000;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 156px auto 16px auto;
}

.signup-input-div {
  position: static;
  display: inline-block;
}

.signup-input {
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 16px 0px 16px 0px;
  font-weight: 700;
  width: 156px;
  font-size: 32px;
  line-height: 108%;
  text-align: left;
  letter-spacing: 0.4px;
  color: #999999;
}

.signup-input.center {
  text-align: center;
}

.signup-input.black {
  color: black;
  width: 32px;
}

.start-body-text {
  position: static;
  display: block;
  margin: 0px auto auto auto;
  width: 250px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #999999;
}

.start-body-text.black {
  position: static;
  text-align: center;
  letter-spacing: 0.4px;
  text-decoration: underline;
  margin-bottom: 32px;
  color: black;
}

ion-slides {
  height: 100%;
  padding: 0px;
  margin: 0px;
  background: white;
}

.swiper-slide {
  padding: 42px 0px 0px 0px;
  align-items: flex-start;
}

.slide {
  display: block;

  background: white;
}

.choose-wallet-button {
  position: static;
  display: flex;
  align-items: center;
  top: auto;
  left: 5vw;
  right: 5vw;
  width: 90vw;
  max-width: 350px;
  height: auto;
  font-weight: bold;
  font-size: 18px;
  padding: 14px 14px 14px 14px;
  border: 2.1251px solid #000000;
  border-radius: 24px;
  background-color: #fff;
  margin: 16px 0px 16px 0px;
}

.choose-wallet-button.grey {
  border: 2.1251px solid #e2e2e2;
}

.wallet-logo {
  display: block;
  margin: 0px 0px 0px 0px;
  width: auto;
  height: auto;
  border-radius: 10px;
}

.wallet-logo.dropshadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
}

.wallet-logo-div {
  display: block;
  width: 42px;
  height: 42px;
  overflow: none;
  margin-right: 42px;
}

.wallet-logo-div.1 {
  margin-right: 64px;
}

.existing-wallet-button {
  position: relative;
  display: block;
  top: auto;
  margin-left: auto;
  margin-right: auto;
  width: 85vw;
  height: auto;
  margin-top: 20px;
  border-radius: 100px;
  background-color: #f4f4f4;
  text-align: center;
  padding: 6px 10px 6px 10px;
}

.existing-wallet-text {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.03em;
  margin-right: 4px;
  color: #999999;
  border-radius: 17.3358px;
}

.existing-wallet-text.black {
  color: black;
}

.right-arrow-div {
  position: block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px auto auto auto;
  width: 52px;
  height: 52px;
  border-radius: 100px;
  background: #f4f4f4;
}

.right-arrow {
  display: block;
  width: 12.59px;
  height: 20.46px;
  margin-left: 2px;
}
