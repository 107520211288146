.public-nav-buttons {
  position: relative;
}

@media screen and (min-width: 600px) {
  .header {
    position: fixed;
    top: 0px;
    width: 100%;
    max-width: 550px;
    margin: 0px 0px 0px 0px;
    padding: 18px 18px 18px 0px;
    border-radius: 0px 0px 12px 12px;
    background: rgba(255, 255, 255, 0.98);
  }
}

.max-width {
  display: block;
  width: 100%;
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
}

.max-width.four-fifty {
  max-width: 450px;
}

.max-width.three-fifty {
  max-width: 350px;
}

.max-width.two-fifty {
  max-width: 250px;
}

.header {
  background: rgba(255, 255, 255, 0.98);
}

.header.no-top-margin {
  margin-top: -96px;
  z-index: 100;
}

.header.four-fifty {
  max-width: 450px;
  margin-top: 18px;
}

.profile-nav {
  position: fixed;
  --ion-grid-padding: 0px;
  z-index: 100;
}

.wallet-button {
  float: right;
  margin: 0px 0px 0px 0px;
  color: black;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  letter-spacing: 0.24px;
  --border-color: rgb(237, 242, 243);
  --background: white;
  --border-width: 2px;
  --border-radius: 12px;
  --padding-end: 18px;
  --padding-start: 18px;
  --background-activated: rgb(237, 242, 243);
  --background-hover: rgb(237, 242, 243);
}

.settings-icon {
  display: block;
  margin-left: auto;
  margin-right: 18px;
  margin-top: 14px;
  width: 26px;
}

.back-arrow {
  max-width: 28px;
}

.back-button {
  color: black;
  float: left !important;
  width: 40px;
  height: 40px;
  --background: rgba(255, 255, 255, 0);
  --border-radius: 32px;
  --margin-end: 2px;
  --padding-start: 8px;
  --padding-end: 10px;
  --background-activated: rgb(237, 242, 243);
  --background-hover: rgb(237, 242, 243);
}

.user-full-name {
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
  position: static;
  margin-left: auto;
  margin-right: auto;
  color: black;
  margin-top: 86px;
  margin-bottom: 0px;
  padding-left: 6px;
}

.user-name {
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  position: static;
  margin-left: auto;
  margin-right: auto;
  color: #9b9b9b;
  margin-top: 4px;
  margin-bottom: 32px;
  padding-left: 6px;
}

.badge.profile {
  width: 36px;
  height: 36px;
  box-shadow: none;
  margin-right: 8px;
  margin-top: 0px;
}

.user-profile-title {
  letter-spacing: 0.1px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
  color: black;
  padding-top: 12px;
  margin: 12px 0px 10px 8px;
}

.city-card {
  display: flex;
  align: end;
  width: 100%;
  height: 55vw;
  max-height: 350px;
  border-radius: 12px;
  border: 2px solid rgb(237, 242, 243);
  background: white;
  overflow: hidden;
  background-image: url("https://uploads-ssl.webflow.com/5d391fec3211a2242af4f911/5e4c4ba0ab8dbd4aea1533de_Screen%20Shot%202020-02-18%20at%203.39.45%20PM.png");
  background-size: cover;
  background-position: center;
  margin-bottom: 16px;
}

.city-title {
  text-align: center;
  letter-spacing: 0.1px;
  font-size: 16px;
  line-height: 10px;
  font-weight: 700;
  color: black;
  padding-top: px;
  margin: 18px 0px 18px 0px;
}

.card-textbox {
  display: block;
  background: white;
  margin-top: auto;
  margin-bottom: 0;
  padding: 1px 0px 1px 0px;
  width: 100%;
  z-index: 4;
}

.building-card {
  border-radius: 12px;
  background: white;
  overflow: hidden;
  background: #ffedc1;
  height: 264px;
  margin-bottom: 8px;
  border: 2px solid rgb(237, 242, 243);
  display: block;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.building-title {
  letter-spacing: 0.1px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: black;
  margin: 8px 0px 4px 0px;
}

.city-sub {
  letter-spacing: 0.1px;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  color: #9b9b9b;
  margin: 1px 0px 1px 0px;
}

.building-card-img {
  display: block;
  position: relative;
  max-width: 150px;
  min-height: 120px;
  max-height: 150px;
  margin: 22px auto 22px auto;
}

.building-card-textbox {
  background: white;
  margin-top: 12px;
  padding: 1px 12px 12px 12px;
  width: 100%;
  height: 100px;
  z-index: 4;
}
