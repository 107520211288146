.bottom-sheet {
  --background: #141414;
  --padding-top: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --overflow: hidden;
  --border-radius: 50px;
  position: absolute;
  width: 100%;
  height: 69.8vh;
  z-index: 10;
  transition: all 0.15s ease;
}

@media only screen and (max-width: 736px) {
  .bottom-sheet.full {
    top: 30.2vh;
  }
}
@media only screen and (min-width: 736px) {
  .bottom-sheet.full {
    top: 55vh;
  }
}

.bottom-sheet.mid {
  top: calc(100% - 130px);
}
.bottom-sheet.closed {
  top: calc(100% - 30px);
}

.bottom-sheet-label {
  margin-top: 4px;
  margin-bottom: 8px;
  margin-left: 12px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #878787;
}

.tab-icon {
  display: block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;
}

.building-name {
  display: block;
  min-width: 90px;
  width: 100px;
  height: 22px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 6px 6px 6px 6px;
  border-radius: 100px;
  background-color: rgb(37, 37, 37);
  color: #fff;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 8px;
}

.building-row {
  height: calc(100% - 220px);
  overflow-y: scroll;

  display: grid;
}

.building-row .inner {
  display: grid;
}

@media only screen and (max-width: 768px) {
  .building-row .inner {
    grid-template-columns: 33vw 33vw 33vw;
    grid-auto-rows: 34vw;
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 768px) {
  .building-row .inner {
    grid-template-columns: 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw;
    grid-auto-rows: 10.5vw;
    padding-bottom: 400px;
  }
}

.building-column {
  padding-top: 0px;
  padding-bottom: 3px;
  padding-right: 2px;
  padding-left: 2px;
  margin: 0 1% 8px 1%;
}
.building-column:last-child {
  margin-bottom: 70px;
}

.building-group {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 12px;
  margin-top: 0px;
  background-color: #333;
}

.building-group:hover {
  background-color: rgb(48, 48, 48);
}

@media only screen and (max-width: 768px) {
  .building-group {
    height: 33vw;
  }
}
@media only screen and (min-width: 768px) {
  .building-group {
    height: 10vw;
  }
}

.building-group.build {
  background: linear-gradient(180deg, #898989 -21.51%, #595959 100%);
  height: 30vw;
  box-shadow: 0px 2vw #3a3a3a;
}

.building-image {
  margin-top: 4px;
  max-width: 80%;
  max-height: 90%;
  margin-left: auto;
  margin-right: auto;
}

.resource-group {
  --padding-bottom: 20px;
  --padding-end: 12px;
  margin-left: 8px;
  --padding-top: 2px;
  height: 52px;
  width: 100%;
  margin-bottom: 16px;

  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.resource-button {
  position: relative;
  display: inline-block;
  padding: 0px 14px 0px 14px;
  background: #bebebf;
  height: 40px;
  margin-right: 8px;
  margin-left: 0px;
  box-shadow: 0px 6px #888888;
  border-radius: 14px;
}

.resource-button:hover {
  opacity: 0.94;
}

.resource-button:active {
  box-shadow: 0px 3px #888888;
  transform: translateY(3px);
}

.resource-icon {
  max-width: 23px;
  margin-right: 12px;
  margin-top: -2px;
}

.resource-image {
  display: inline-block;
  width: 23px;
}

.resource-image img {
  position: absolute;
  top: 11px;
}

.resource-text {
  display: inline-block;
  color: black;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-transform: none;
  margin-top: 12px;
  margin-left: 5px;
}

.asphalt {
  background: linear-gradient(180deg, #898989 -21.51%, #595959 100%);
  box-shadow: 0px 6px #3a3a3a;
}

.asphalt:active {
  box-shadow: 0px 3px #3a3a3a;
  transform: translateY(3px);
}

.concrete {
  background: linear-gradient(180deg, #e8e8e8 0%, #bebebf 100%);
  box-shadow: 0px 6px #939393;
}

.concrete:active {
  box-shadow: 0px 3px #939393;
  transform: translateY(3px);
}

.quartz {
  background: linear-gradient(180deg, #ffe5db 0%, #eec5b5 100%);
  box-shadow: 0px 6px #c69380;
}

.quartz:active {
  box-shadow: 0px 3px #c69380;
  transform: translateY(3px);
}

.water {
  background: linear-gradient(180deg, #cff9ff 0%, #64d2e3 100%);
  box-shadow: 0px 6px #4eaebd;
}

.water:active {
  box-shadow: 0px 3px #4eaebd;
  transform: translateY(3px);
}

.deciduous {
  background: linear-gradient(180deg, #A5E787 0%, #5C9D38 100%);
  box-shadow: 0px 6px #3E7022;
}

.deciduous:active {
  box-shadow: 0px 3px #3E7022;
  transform: translateY(3px);
}

.blossom {
  background: linear-gradient(180deg, #FFE9F8 0%, #FFB0CB 100%);
  box-shadow: 0px 6px #F298B8;
}

.blossom:active {
  box-shadow: 0px 3px #F298B8;
  transform: translateY(3px);
}

.evergreen {
  background: linear-gradient(180deg, #5FA68C 0%, #295844 100%);
  box-shadow: 0px 6px #183D2D;
}

.evergreen:active {
  box-shadow: 0px 3px #183D2D;
  transform: translateY(3px);
}

.rainbow {
  background: linear-gradient(180deg, #e57dff -21.51%, #7c4ade 100%);
  box-shadow: 0px 6px #4d2b8f;
}

.rainbow:active {
  box-shadow: 0px 3px #4d2b8f;
  transform: translateY(3px);
}

.brooklyn {
  background: linear-gradient(180deg, #ffa96b 20.31%, #ff996d 100%);
  box-shadow: 0px 6px #c85a2b;
}

.brooklyn:active {
  box-shadow: 0px 3px #c85a2b;
  transform: translateY(3px);
}

.label {
  --margin-top: 0px;
  --margin-bottom: 24px;
}

.build-building-icon {
  margin-top: 6px;
  max-width: 25px;
  position: absolute;
}

.build-building-text {
  padding-left: 30px;
}
