.nav-bar {
  --background: transparent;
}

.header-grid {
  position: relative;
  z-index: 10;
  margin: 0px 14px 0px 14px;
}

.top-nav-button {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 44px;
  height: 41px;
  margin: 0px auto auto 0px;
  border-radius: 12px;
  padding: 12px 10px 10px 15px;
  background: linear-gradient(180deg, #60bafe 0%, #4592ed 100%);
  box-shadow: 0px 6px #2873ca;
}

.top-nav-button:hover {
  opacity: 0.94;
}

.top-nav-button:active {
  box-shadow: 0px 3px #2873ca;
  transform: translateY(0px);
}

.top-nav-button.red {
  background: linear-gradient(180deg, #fa6a8d 0%, #eb5454 100%);
  box-shadow: 0px 6px #be3a3c;
}

.top-nav-button.red:active {
  box-shadow: 0px 3px #be3a3c;
}

.top-nav-button.green {
  background: linear-gradient(180deg, #35c76f 0%, #35c781 100%);
  box-shadow: 0px 6px #169a58;
}

.top-nav-button.green:active {
  box-shadow: 0px 3px #169a58;
}

.button-text {
  color: white;
  font-weight: 700;
  margin-top: 0px;
  text-align: center;
}

.top-nav-button.text {
  width: 72px;
  padding: 14px 16px 0px 16px;
}

.top-nav-outline {
  position: static;
  left: 0%;
  top: 0%;
  width: 48px;
  height: 51px;
  padding: 2px 2px 0px 2px;
  margin-left: 0px;
  margin-bottom: 14px;
  border-radius: 14px;
  background-color: #fff;
}

.top-nav-outline:active {
  height: 48px;
  transform: translateY(3px);
}

.top-nav-outline.view {
  margin-left: 8px;
}

.top-nav-outline.text {
  width: 76px;
}

.top-nav-outline.right {
  float: right;
  display: block;
  margin-right: 0px;
}

.top-nav-info {
  display: inline-block;
  left: 0%;
  top: 0%;
  height: 51px;
  margin: 0px 0px 0px 0px;
  padding: 17px 18px 0px 18px;
  border-radius: 14px;
  background-color: #161719;
}

.info-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav-icon {
  padding: 0px 0px 0px 0px;
  width: 16px;
}

ion-toast {
  top: 10px;
}

.counter-div {
  display: flex;
  align-items: center;
  border: 0px solid #f4f4f4;
  border-radius: 12px;
  padding: 0px 6px 0px 6px;
  height: 16px;
  max-width: 104px;
  margin: 0px 0px 0px 2px;
  background: rgba(22, 23, 25, 0.9);
  border-radius: 4px 6px 6px 0px;
  border: 1px solid rgb(53, 53, 53);
}

.counter-div.buildings {
  max-width: 45px;
  background: rgba(43, 83, 163, 0.9);
  border: 1px solid rgba(19, 46, 99, 0.9);
  border-radius: 0px 6px 6px 0px;
}

.counter-div.roads {
  max-width: 55px;
  background: rgba(68, 133, 238, 0.9);
  border: 1px solid rgba(40, 94, 180, 0.9);
  border-radius: 0px 6px 6px 0px;
}

.counter-div.trees {
  max-width: 45px;
  background: rgba(42, 160, 91, 0.9);
  border: 1px solid rgba(16, 121, 60, 0.9);
  border-radius: 0px 6px 6px 4px;
}

.counter-text {
  display: inline-block;
  padding: 0px;
  margin: 0px;
  font-weight: 800;
  font-size: 9px;
  line-height: 10px;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}

.counter-text.small {
  font-size: 6px;
  color: rgba(255, 255, 255, 0.74);
  margin-left: 3px;
  margin-top: 1px;
}

.counter-icon {
  max-width: 8px;
  margin-right: 4px;
}

.unveil-button {
  position: static;
  width: auto;
  text-align: center;
  color: white;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 21px;
  padding: 24px 24px 24px 24px;
  background: linear-gradient(180deg, #ff9ce3 -21.51%, #e841a0 100%);
  box-shadow: 0px 8px #9f236b;
}

.unveil-button-outline {
  position: absolute;
  display: block;
  top: auto;
  bottom: 56px;
  left: 10vw;
  right: 10vw;
  width: 80vw;
  height: auto;
  padding: 3px 3px 11px 3px;
  margin-bottom: 12px;
  border-radius: 24px;
  background-color: #fff;
}

.construction-div {
  display: block;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0%;
  right: 0%;
  width: 230px;
  height: 51px;
  padding: 14px 15px 0px 23px;
  border-radius: 100px;
  background-color: #fcdb77;
}

.construction-text {
  display: inline;
  color: black;
  font-weight: 900;
  font-size: 12.4px;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0);
  border-radius: 4.27339px;
  margin: 0px 8px 0px 2px;
  text-align: center;
}

.construction-text.italic {
  font-style: italic;
}

ion-toast {
  --background: black;
  --color: white;
  --height: 54px;
  --max-width: 250px;
  --border-radius: 14px;
}

.city-logo {
  display: block;
  margin-top: 2px;
  width: 45px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
}

.discord-logo {
  width: 23px;
  height: 23px;
  margin-right: 10px;
  margin-top: 6px;
}

.discord-text {
  display: block;
  margin-top: 16px;
  color: white;
  font-weight: 700;
  font-size: 12px;
  line-height: 34px;
  margin-top: 0px;
  text-align: center;
}

.discord-group-container {
  display: flex;
  justify-content: center;
  margin-left: 0px;
}

.discord-group {
  display: flex;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: auto;
  justify-content: center;
  text-decoration: none;
  width: 174px;
  bottom: 20px;
  height: 36px;
  padding: 0px 4px 0px 0px;
  border-radius: 100px;
  background-color: #161719;
}
