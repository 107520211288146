.kiosk-nav-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 4px 12px 10px 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #ffffff;
  z-index: 100;
  border-radius: 20px;
}

.image-65 {
  position: absolute;
  left: auto;
  top: 0%;
  right: auto;
  bottom: auto;
  display: block;
  width: 42px;
  height: 42px;
  top: 3px;
  left: calc(50% - 20px);
}

.nav-text {
  margin-top: 2px;
  margin-left: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  text-decoration: none;
}

@media screen and (max-width: 479px) {
  .button-join-beta {
    display: none;
  }
}

.main-nav-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 35px;
  margin-right: 8px;
  margin-left: 0px;
  padding: 8px 12px 8px 13px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 14px;
  background-color: #4d92ff;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(20%, #ffa96b),
    to(#ff996d)
  );
  background-image: linear-gradient(180deg, #ffa96b 20%, #ff996d);
  box-shadow: 0 6px 0 0 #c85a2b;
  letter-spacing: 2px;
  text-decoration: none;
}

.main-nav-button.rainbow {
  width: 50px;
  padding-left: 15px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(20%, #e57dff),
    to(#7c4ade)
  );
  background-image: linear-gradient(180deg, #e57dff 20%, #7c4ade);
  box-shadow: 0 6px 0 0 #4d2b8f;
}

.image-97 {
  margin-right: 0px;
  padding-left: 0px;
}

.nav-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-div.middle {
  width: 10%;
}

.nav-div.right {
  overflow: visible;
  height: auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.nav-text-link {
  margin-top: 2px;
  margin-left: 16px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #000;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-decoration: none;
}
