.settings-group {
    margin-top: 72px;
    padding: 12px;
}

.settings-label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #9B9B9B;
    margin: 0px 0px 16px 0px;
}

.settings-input {
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 34px;
    color: #000000;
    margin: 0px 0px 56px 0px;
}

.settings-input.grey {
    color: #9B9B9B;
    margin-right: 4px;

}