.wallet-price {
  margin: 84px 0px 0px 0px;
  font-size: 60px;
  line-height: 60px;
  font-weight: 800;
  text-align: center;
}

.eth-amount {
  font-size: 24px;
  margin: 8px 0px 56px 0px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  color: #9b9b9b;
}

.wallet-address {
  user-select: all;
  text-align: center;
}

.wallet-address .label {
  user-select: none;
}

.half-width-button {
  width: 100%;
  height: 64px;
  margin: 0px auto 10px 0px;
  border-radius: 16px;
  padding: 12px 10px 10px 15px;
  background: white;
  width: 100%;
  padding: 2px 2px 0px 2px;
  margin-left: 0px;
  border-radius: 24px;
  background-color: #fff;
  border: 2.1251px solid #000000;
}

.half-width-button.grey {
  border: 2.1251px solid #e2e2e2;
}

.button-text-large {
  color: black;
  font-weight: 700;
  font-size: 24px;
  margin-top: 14px;
  text-align: center;
}

.transaction-description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #8a8a8a;
}

.transaction-amount {
  float: right;
  color: black;
  --font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
}

.no-background {
  --background: rgba(255, 255, 255, 0);
  background: rgba(255, 255, 255, 0);
}
